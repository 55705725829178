import { ContractInterface, type BigNumber } from 'ethers'

import { Contract } from '..'
import { glpExtendAbi } from '../../assets'
import { Utils } from '../../types'

export class GlpExtendContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = glpExtendAbi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }

  async extend(tokenId: BigNumber, numberOfYears: BigNumber) {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.extend(tokenId, numberOfYears)

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }
}
