import { BigNumber, ContractInterface } from 'ethers'

import { glpOldWithdrawAbi } from '../../assets'
import { Contract } from '../../index'
import type { Utils } from '../../types'

export class GlpOldWithdrawContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = glpOldWithdrawAbi
  ) {
    super(address, chain, abi)
  }

  async withdraw(
    id: number,
    amount: BigNumber,
    subscriptionEndsAt: number,
    proof: Utils.Proof
  ): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.withdraw(
        id,
        amount,
        subscriptionEndsAt,
        proof
      )

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }

  async subscriptionWithdrawnAt(
    wallet: string,
    subscriptionId: number
  ): Promise<number> {
    const subscriptionWithdrawnAt = await this.adapter!.subscriptionWithdrawnAt(
      wallet,
      subscriptionId
    )

    return subscriptionWithdrawnAt.toNumber()
  }
}
