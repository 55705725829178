import { useContext, useEffect, useState } from 'react'

import { Utils, walletContext } from '../..'
import { InfinityNftWithdrawalFeeContract } from '../../contracts'

export const useInfinityNftWithdrawalFeeContract = (network: Utils.Network) => {
  const wallet = useContext(walletContext)

  const [
    infinityNftWithdrawalFeeContract,
    setInfinityNftWithdrawalFeeContract,
  ] = useState<InfinityNftWithdrawalFeeContract | undefined>()

  useEffect(() => {
    if (!wallet) return

    const infinityNftWithdrawalFeeContract =
      new InfinityNftWithdrawalFeeContract(network)

    setInfinityNftWithdrawalFeeContract(infinityNftWithdrawalFeeContract)
  }, [wallet, network])

  return { infinityNftWithdrawalFeeContract }
}
