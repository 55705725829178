import { config } from '../config'
import { Agreements } from '../types'

export const fetchAgreementMessage = (): Promise<string> => {
  return fetch(config.agreementsApiUrl)
    .then(response => response.json())
    .then(({ message }) => message)
}

export const fetchWalletAgreement = (
  account: string
): Promise<Agreements.Agreement> => {
  return fetch(`${config.agreementsApiUrl}/${account}`)
    .then(response => response.json())
    .then(({ agreement }) => agreement)
}

export const postWalletAgreement = (
  account: string,
  message: string,
  signature: string
): Promise<boolean> => {
  return fetch(config.agreementsApiUrl, {
    method: 'POST',
    body: JSON.stringify({ wallet: account, message, signature }),
  })
    .then(response => response.json())
    .then(({ message }) => message)
}
