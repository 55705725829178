import { Buffer } from 'buffer'

import { BigNumber, ethers } from 'ethers'
import { Subject } from 'rxjs'

import {
  fetchAgreementMessage,
  fetchWalletAgreement,
  postWalletAgreement,
} from '../agreements'
import type { Connector } from '../connectors'
import {
  BitgetConnector,
  MetaMaskConnector,
  OkxConnector,
  WalletConnectConnector,
} from '../connectors'
import type { Contract } from '../contracts'
import type { Agreements } from '../types'
import { Utils } from '../types'

if (typeof window !== 'undefined') window.Buffer = Buffer

export enum WalletProvider {
  MetaMask = 'metamask',
  WalletConnect = 'walletconnect',
  Okx = 'okx',
  Bitget = 'bitget',
}

export class Wallet {
  chain = 0

  account: string | null = null

  isProcessing = false

  isTxPending = false

  provider: WalletProvider | null = null

  protected connector: Connector | null = null

  protected connectors: { [key in WalletProvider]: Connector } = {
    metamask: new MetaMaskConnector(),
    walletconnect: new WalletConnectConnector(),
    okx: new OkxConnector(),
    bitget: new BitgetConnector(),
  }

  get isConnected() {
    return !!this.account
  }

  protected _changed$: Subject<void> = new Subject()
  readonly changed$ = this._changed$.asObservable()

  agreementPromise?: Promise<Agreements.Agreement | null> = undefined

  constructor() {
    const account = localStorage.getItem('web3WalletAccount')
    const provider = localStorage.getItem('web3WalletProvider')

    if (!account || !provider) return

    this.connect((this.provider = provider as WalletProvider))
  }

  get isMetaMask() {
    const isProviderMM = this.provider === WalletProvider.MetaMask
    const isWalletConnectMM =
      this.connector?.walletConnectSessionName === 'MetaMask Wallet'

    return isProviderMM || isWalletConnectMM
  }

  async connect(provider: WalletProvider) {
    if (typeof document === 'undefined') return

    if (!this.connectors.hasOwnProperty(provider)) return

    this.provider = provider

    this.connector = this.connectors[provider]

    await this.connector.connect()

    this.chain = await this.connector.chain()
    this.account = await this.connector.account()

    this.agreementPromise = fetchWalletAgreement(this.account)

    this._changed$.next()

    localStorage.setItem('web3WalletAccount', this.account)
    localStorage.setItem('web3WalletProvider', provider)

    this.connector.chainChanged().subscribe(async () => {
      this.chain = await this.connector!.chain()

      this._changed$.next()
    })

    this.connector.accountsChanged().subscribe(async accounts => {
      if (accounts.length === 0) return this.disconnect()

      this.account = await this.connector!.account()

      this.agreementPromise = fetchWalletAgreement(this.account)

      this._changed$.next()

      localStorage.setItem('web3WalletAccount', this.account)
    })

    this.connector.disconnectChanged().subscribe(() => this.disconnect())
  }

  async disconnect() {
    this.chain = 0
    this.account = null
    this.agreementPromise = undefined

    this._changed$.next()

    this.connector?.disconnect()
    this.provider = null

    localStorage.removeItem('web3WalletAccount')
    localStorage.removeItem('web3WalletProvider')
  }

  interact<T extends Contract>(contract: T): T {
    if (!contract.address) {
      console.warn('Contract interaction: address is not defined!')

      return contract
    }

    return contract.connect(this, this.connector!.signer!)
  }

  isOnChain(chain: Utils.Network) {
    return this.chain === Number(chain)
  }

  sign(value: string) {
    return this.connector!.sign(value)
  }

  signMessage(value: string) {
    return this.connector!.signMessage(value)
  }

  async signTypedData(
    domain: Record<string, unknown>,
    types: Record<string, ethers.TypedDataField[]>,
    value: Record<string, unknown>
  ) {
    return this.connector!.signTypedData(domain, types, value)
  }

  public async signAgreement() {
    if (!this.account || this.isProcessing || !this.connector) return

    this.asBusy()

    const agreementMessage = await fetchAgreementMessage()

    const currentDate = new Date().toLocaleString('en-US', {
      timeZone: 'UTC',
    })

    const agreement = [
      agreementMessage,
      `Wallet address: ${this.account}`,
      `Date: ${currentDate} UTC`,
    ]

    const message = agreement.join('\n\n')

    const signature = await this.signMessage(message).catch(() => '')
    if (!signature) return this.asIdle()

    await postWalletAgreement(this.account, message, signature)

    this.agreementPromise = fetchWalletAgreement(this.account)

    this.asIdle()
  }

  async addNetwork(chain: Utils.Network): Promise<boolean> {
    if (this.isProcessing || !this.connector) return false

    if (this.isOnChain(chain)) return true

    this.asBusy()

    return await this.connector
      .addChain(chain)
      .then(() => true)
      .catch(() => false)
      .finally(() => this.asIdle())
  }

  async switchNetwork(chain: Utils.Network): Promise<boolean> {
    if (this.isProcessing || !this.connector) return false

    if (this.isOnChain(chain)) return true

    this.asBusy()

    return await this.connector
      .switchChain(chain)
      .then(() => true)
      .catch(async ({ code }) => {
        const unrecognizedChainCodes = [4902, 5000]

        if (unrecognizedChainCodes.includes(code)) {
          this.asIdle()

          return await this.addNetwork(chain)
        }

        return false
      })
      .finally(() => this.asIdle())
  }

  async addToken(
    chain: Utils.Network,
    address: string,
    symbol: string,
    decimals: string,
    image?: string
  ): Promise<boolean> {
    if (!this.isMetaMask || this.isProcessing || !this.connector) return false

    const isOnChain = await this.switchNetwork(chain)
    if (!isOnChain) return false

    this.asBusy()

    return await this.connector
      .watchAsset(chain, address, symbol, decimals, image)
      .then(() => true)
      .catch(() => false)
      .finally(() => this.asIdle())
  }

  async getBalance(address: string): Promise<BigNumber> {
    return await this.connector!.getBalance(address)
  }

  public async getGasPrice(): Promise<BigNumber> {
    return await this.connector!.getGasPrice()
  }

  asBusy(): void {
    this.isProcessing = true

    this._changed$.next()
  }

  asIdle(): void {
    this.isProcessing = false
    this.isTxPending = false

    this._changed$.next()
  }

  asTxPending(): void {
    this.isTxPending = true

    this._changed$.next()
  }
}
