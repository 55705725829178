import { BigNumber } from 'ethers'

export namespace Utils {
  export enum Network {
    ETHEREUM = 1,
    ROPSTEN = 3,
    RINKEBY = 4,
    GOERLI = 5,
    SMART_CHAIN = 56,
    SMART_CHAIN_TESTNET = 97,
    POLYGON = 137,
    ARBITRUM_ONE = 42_161,
    BASE = 8453,
    PHALCON_BSC = 9991,
    PHALCON_ETH = 9992,
    ANVIL_BSC = 31_337,
    ANVIL_ETH = 31_338,
  }

  export type Addresses = Partial<Record<Network, string>>

  export type Proof = string[]

  export interface ContractMethodOverrides {
    from?: string
    value?: BigNumber
    gasPrice?: BigNumber
    gasLimit?: BigNumber
    nonce?: number
  }
}
