import { ethers } from 'ethers'
import { hexValue } from 'ethers/lib/utils.js'
import { fromEvent, Subject, Subscription } from 'rxjs'
import { SessionTypes } from '@walletconnect/types'

import { chains } from '../common'
import { Utils } from '../types'

export abstract class Connector {
  protected provider?: ethers.providers.Web3Provider
  protected walletConnectSession?: SessionTypes.Struct

  protected listeners: Subscription = Subscription.EMPTY

  protected chainChanged$: Subject<void> = new Subject()
  protected accountsChanged$: Subject<string[]> = new Subject()
  protected disconnect$: Subject<void> = new Subject()

  get walletConnectSessionName() {
    const { metadata } = this.walletConnectSession?.peer || {}

    return metadata?.name
  }

  get signer() {
    return this.provider?.getSigner()
  }

  async chain(): Promise<number> {
    const { chainId = 0 } = await this.provider!.getNetwork()

    return Number(chainId)
  }

  async account(): Promise<string> {
    const [account] = await this.provider?.send('eth_requestAccounts', [])

    return account?.toLowerCase()
  }

  async connect() {
    await this.activate()

    const provider = this.provider!.provider as NonNullable<
      typeof window.ethereum
    >

    this.chainChanged$ = new Subject()
    this.accountsChanged$ = new Subject<string[]>()
    this.disconnect$ = new Subject()

    const chainChanged = fromEvent(provider, 'chainChanged').subscribe(() =>
      this.chainChanged$.next()
    )

    const accountsChanged = fromEvent(provider, 'accountsChanged').subscribe({
      next: accounts => this.accountsChanged$.next(accounts as string[]),
    })

    const disconnect = fromEvent(provider, 'disconnect').subscribe(
      (error: unknown) => {
        // https://github.com/MetaMask/metamask-extension/issues/13375
        // @ts-expect-error
        if (error.code === 1013) {
          console.warn(
            'MetaMask logged connection error 1013: "Try again later"'
          )

          return
        }

        return this.disconnect$.next()
      }
    )

    this.listeners = new Subscription()

    this.listeners.add(chainChanged)
    this.listeners.add(accountsChanged)
    this.listeners.add(disconnect)
  }

  async disconnect() {
    await this.deactivate()

    this.listeners.unsubscribe()

    this.chainChanged$.complete()
    this.accountsChanged$.complete()
    this.disconnect$.complete()
  }

  async sign(value: string) {
    return await this.provider?.send('personal_sign', [value, this.account()])
  }

  async signMessage(value: string) {
    return await this.signer!.signMessage(value)
  }

  async signTypedData(
    domain: Record<string, unknown>,
    types: Record<string, ethers.TypedDataField[]>,
    value: Record<string, unknown>
  ) {
    return await this.signer!._signTypedData(domain, types, value)
  }

  async getBalance(address: string) {
    return await this.provider!.getBalance(address)
  }

  async getGasPrice() {
    return await this.provider!.getGasPrice()
  }

  async addChain(chain: Utils.Network) {
    const { name, rpcUrls, currency } = chains[chain]
    const chainId = hexValue(Number(chain))

    return await this.provider!.send('wallet_addEthereumChain', [
      {
        chainId,
        chainName: name,
        nativeCurrency: {
          symbol: currency,
          decimals: 18,
        },
        rpcUrls,
      },
    ])
  }

  async switchChain(chain: Utils.Network) {
    const chainId = hexValue(Number(chain))

    // doesn't work with walletconnect v2
    // see https://github.com/MetaMask/metamask-mobile/issues/6655 & https://github.com/WalletConnect/WalletConnectFlutterV2/issues/138
    return await this.provider!.send('wallet_switchEthereumChain', [
      { chainId },
    ])
  }

  async watchAsset(
    chain: Utils.Network,
    address: string,
    symbol: string,
    decimals: string,
    image?: string
  ) {
    return await this.provider!.send('wallet_watchAsset', {
      // @ts-ignore https://github.com/ethers-io/ethers.js/issues/2576#issuecomment-1065858131
      type: 'ERC20',
      options: {
        address,
        symbol,
        decimals,
        image,
      },
    })
  }

  chainChanged() {
    return this.chainChanged$.asObservable()
  }

  accountsChanged() {
    return this.accountsChanged$.asObservable()
  }

  disconnectChanged() {
    return this.disconnect$.asObservable()
  }

  protected abstract activate(): Promise<void> | void

  protected abstract deactivate(): Promise<void> | void
}
