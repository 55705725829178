import { useContext, useEffect, useState } from 'react'

import { config } from '../../config'
import { ZealyRewardsContract } from '../../contracts'
import { Utils } from '../../types'
import { walletContext } from '../../wallet'

export const useZealyRewardsContract = (isDevelopment: boolean) => {
  const wallet = useContext(walletContext)

  const [zealyRewardsContract, setZealyRewardsContract] = useState<
    ZealyRewardsContract | undefined
  >()

  const contractNetwork = isDevelopment
    ? Utils.Network.SMART_CHAIN_TESTNET
    : Utils.Network.SMART_CHAIN

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const zealyRewardsContract = new ZealyRewardsContract(
      config.zealy.rewards[contractNetwork]!,
      contractNetwork
    )

    setZealyRewardsContract(zealyRewardsContract)
  }, [wallet, contractNetwork])

  return { zealyRewardsContract }
}
