import { ContractInterface } from 'ethers'

import { glpLifetimeMembershipAbi } from '../../assets'
import { Utils } from '../../types'
import { ERC721Contract } from '../index'

export class GlpLifetimeMembershipContract extends ERC721Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = glpLifetimeMembershipAbi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }
}
