import { Contract as ContractAdapter, ContractInterface, ethers } from 'ethers'
import { MulticallWrapper } from 'ethers-multicall-provider'

import { Utils } from '../../types'
import type { Wallet } from '../../wallet'

export class Contract {
  get chain() {
    return this._chain
  }

  get address() {
    return this._address
  }

  get connectable() {
    return !!this.adapter
  }

  protected _chain: Utils.Network

  protected _address: string

  protected _rpcProvider: ethers.providers.Provider | undefined

  protected wallet: Wallet | null = null

  protected adapter: ContractAdapter | null = null

  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface,
    rpcUrl?: string
  ) {
    this._chain = chain
    this._address = address

    try {
      if (rpcUrl) {
        const jsonRpcProvider = new ethers.providers.JsonRpcProvider({
          url: rpcUrl,
          skipFetchSetup: true,
        })

        this._rpcProvider = MulticallWrapper.wrap(jsonRpcProvider)
      }

      this.adapter = new ethers.Contract(address, abi, this._rpcProvider)
    } catch {
      this.adapter = null
    }
  }

  async rpcRead(method: string, props: unknown) {
    if (!this._rpcProvider) {
      throw new Error('The contract has no rpcProvider defined')
    }

    return await this.adapter![method](props)
  }

  connect(
    wallet: Wallet,
    signer: string | ethers.Signer | ethers.providers.Provider
  ) {
    this.wallet = wallet
    this.adapter = this.adapter!.connect(signer)

    return this
  }
}
