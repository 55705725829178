import { BigNumber, ContractInterface } from 'ethers'

import { zealyRewardsAbi } from '../../assets'
import { Contract } from '..'
import { Utils } from '../../types'

export class ZealyRewardsContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = zealyRewardsAbi
  ) {
    super(address, chain, abi)
  }

  async setNewRound(root: string): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.setNewRound(root)
      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()
      throw error
    }

    this.wallet!.asIdle()
  }

  async claimReward(
    amount: BigNumber,
    proofs: string[],
    txHash?: (hash: string) => void
  ): Promise<string | void> {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.claimReward(amount, proofs)

      this.wallet!.asTxPending()
      if (txHash) {
        txHash(tx.hash)
      }

      await tx.wait()

      this.wallet!.asIdle()
      return tx.hash
    } catch (error) {
      this.wallet!.asIdle()
      throw error
    }
  }

  async setRootHash(newRoot: string): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.setRootHash(newRoot)
      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()
      throw error
    }

    this.wallet!.asIdle()
  }

  async setToken(tokenAddress: string): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.setToken(tokenAddress)
      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()
      throw error
    }

    this.wallet!.asIdle()
  }

  async getOwner(): Promise<string> {
    return await this.adapter!.owner()
  }

  async getRoundId(): Promise<number> {
    return await this.adapter!.roundId()
  }

  async getRewardClaimedAt(address: string): Promise<Date> {
    const roundId = await this.getRoundId()
    return await this.adapter!.rewardClaimedAt(address, roundId)
  }

  async getRootHash(): Promise<string> {
    return await this.adapter!.rootHash()
  }
}
