import { ethers } from 'ethers'

import { Connector } from './connector'

export class OkxConnector extends Connector {
  protected activate(): void | Promise<void> {
    this.provider = new ethers.providers.Web3Provider(window.okexchain!, 'any')
  }

  protected deactivate(): void | Promise<void> {}
}
