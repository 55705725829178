import type { ContractInterface, ContractTransaction } from 'ethers'

import { zealyNftAbi } from '../../assets'
import { Contract } from '..'
import { Utils } from '../../types'

export class ZealyNftContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = zealyNftAbi
  ) {
    super(address, chain, abi)
  }

  async mint(
    tokenId: string,
    proofs: string[],
    txHash?: (hash: string) => void
  ): Promise<void | string> {
    this.wallet!.asBusy()

    try {
      const tx: ContractTransaction = await this.adapter![
        'mint(uint256,bytes32[])'
      ](tokenId, proofs)

      this.wallet!.asTxPending()
      if (txHash) {
        txHash(tx.hash)
      }

      await tx.wait()

      this.wallet!.asIdle()
      return tx.hash
    } finally {
      this.wallet!.asIdle()
    }
  }
}
