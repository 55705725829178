import { useContext, useEffect, useState } from 'react'

import { TensetXInfinityVaultBurnExchangerContract } from '../../contracts'
import { Utils } from '../../types'
import { walletContext } from '../../wallet'

export const useTensetXInfinityVaultBurnExchangerContract = () => {
  const wallet = useContext(walletContext)

  const [
    tensetXInfinityVaultBurnExchangerContract,
    setTensetXInfinityVaultBurnExchangerContract,
  ] = useState<TensetXInfinityVaultBurnExchangerContract | undefined>()

  const contractNetwork = Utils.Network.SMART_CHAIN

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const tensetXInfinityVaultBurnExchangerContract =
      new TensetXInfinityVaultBurnExchangerContract(contractNetwork)

    setTensetXInfinityVaultBurnExchangerContract(
      tensetXInfinityVaultBurnExchangerContract
    )
  }, [wallet, contractNetwork])

  return { tensetXInfinityVaultBurnExchangerContract }
}
