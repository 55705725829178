import { useContext, useEffect, useState } from 'react'

import { walletContext } from '../..'
import { InfinityVaultBridgeContract } from '../../contracts'
import { Utils } from '../../types'

export const useInfinityVaultBridgeContract = () => {
  const wallet = useContext(walletContext)

  const [infinityVaultBridgeContract, setInfinityVaultBridgeContract] =
    useState<InfinityVaultBridgeContract | undefined>()

  const contractNetwork = Utils.Network.SMART_CHAIN

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const infinityVaultBridgeContract = new InfinityVaultBridgeContract(
      contractNetwork
    )

    setInfinityVaultBridgeContract(infinityVaultBridgeContract)
  }, [wallet, contractNetwork])

  return { infinityVaultBridgeContract }
}
