import { ethers } from 'ethers'
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import { config } from '../config'
import { chains } from '../common'
import { Utils } from '..'

import { Connector } from './connector'

export class WalletConnectConnector extends Connector {
  protected async activate(): Promise<void> {
    const rpcMap = Object.fromEntries(
      Object.entries(chains).map(([chainId, { rpcUrls }]) => [
        chainId,
        rpcUrls[0],
      ])
    )

    // https://docs.walletconnect.com/2.0/web/providers/ethereum#required-and-optional-chains & https://github.com/WalletConnect/walletconnect-monorepo/issues/2905#issuecomment-1618238606
    const requiredChain = Utils.Network.SMART_CHAIN
    const optionalChains = Object.keys(chains)
      .map(Number)
      .filter(chainId => chainId !== requiredChain)

    // https://docs.walletconnect.com/2.0/web/providers/ethereum#required-and-optional-methods & https://github.com/WalletConnect/walletconnect-monorepo/issues/2845#issue-1779155133
    const optionalMethods = [
      'eth_accounts',
      'eth_requestAccounts',
      'eth_sendRawTransaction',
      'eth_sign',
      'eth_signTransaction',
      'eth_signTypedData',
      'eth_signTypedData_v3',
      'eth_signTypedData_v4',
      'wallet_switchEthereumChain',
      'wallet_addEthereumChain',
      'wallet_getPermissions',
      'wallet_requestPermissions',
      'wallet_registerOnboarding',
      'wallet_watchAsset',
      'wallet_scanQRCode',
      'eth_sendTransaction',
      'personal_sign',
    ]

    const provider = await EthereumProvider.init({
      projectId: config.walletConnectProjectId,
      chains: [requiredChain],
      optionalChains,
      optionalMethods,
      optionalEvents: ['chainChanged', 'accountsChanged'],
      showQrModal: true,
      qrModalOptions: {
        themeMode: 'dark',
        themeVariables: {
          '--wcm-z-index': '9999',
        },
      },
      rpcMap,
    })

    return new Promise(async (resolve, reject) => {
      try {
        await provider.enable()

        this.provider = new ethers.providers.Web3Provider(provider, 'any')
        this.walletConnectSession = provider.session

        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  protected async deactivate(): Promise<void> {
    const { provider } = this.provider as unknown as {
      provider: WalletConnectConnector
    }

    this.walletConnectSession = undefined

    await provider.disconnect()
  }
}
