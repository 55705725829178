import type { BigNumber, ContractInterface, ContractTransaction } from 'ethers'

import { erc721Abi } from '../../assets'

import { Contract } from './contract'

export class ERC721Contract extends Contract {
  constructor(
    address: string,
    chain = 1,
    abi: ContractInterface = erc721Abi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }

  public balanceOf(address: string): Promise<BigNumber> {
    return this.adapter!.balanceOf(address)
  }

  public totalSupply(): Promise<BigNumber> {
    return this.adapter!.totalSupply()
  }

  public isApprovedForAll(owner: string, operator: string): Promise<boolean> {
    return this.adapter!.isApprovedForAll(owner, operator)
  }

  public async safeTransferFrom(
    from: string,
    to: string,
    tokenId: BigNumber
  ): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx: ContractTransaction = await this.adapter![
        'safeTransferFrom(address,address,uint256)'
      ](from, to, tokenId)

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }

  public async setApprovalForAll(
    operator: string,
    approved = true
  ): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx: ContractTransaction = await this.adapter!.setApprovalForAll(
        operator,
        approved
      )

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }

  public tokenOfOwnerByIndex(
    owner: string,
    index: BigNumber
  ): Promise<BigNumber> {
    return this.adapter!.tokenOfOwnerByIndex(owner, index)
  }
}
