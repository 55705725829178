import { useContext, useEffect, useState } from 'react'

import { config } from '../../config'
import { ZealyNftContract } from '../../contracts'
import { Utils } from '../../types'
import { walletContext } from '../../wallet'

export const useZealyNftContract = (isDevelopment = false) => {
  const wallet = useContext(walletContext)

  const [zealyNftContract, setZealyNftContract] = useState<
    ZealyNftContract | undefined
  >()

  const contractNetwork = isDevelopment
    ? Utils.Network.SMART_CHAIN_TESTNET
    : Utils.Network.SMART_CHAIN

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const zealyNftContract = new ZealyNftContract(
      config.zealy.nft[contractNetwork]!,
      contractNetwork
    )

    setZealyNftContract(zealyNftContract)
  }, [wallet, contractNetwork])

  return { zealyNftContract }
}
