import { ContractInterface } from 'ethers'

import { Contract } from '..'
import { erc721MultisenderAbi } from '../../assets'
import { Utils } from '../../types'

export class Erc721MultisenderContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = erc721MultisenderAbi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }

  async batchSafeTransferFrom(
    to: string,
    collections: string[],
    ids: number[]
  ): Promise<string> {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.batchSafeTransferFrom(to, collections, ids)

      await tx.wait()

      return tx.hash
    } finally {
      this.wallet!.asIdle()
    }
  }
}
