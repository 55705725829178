import type { BigNumber, ContractInterface, ContractTransaction } from 'ethers'
import { ethers } from 'ethers'

import { erc20Abi } from '../../assets'

import { Contract } from './contract'

export class ERC20Contract extends Contract {
  constructor(
    address: string,
    chain = 56,
    abi: ContractInterface = erc20Abi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }

  balanceOf(address: string): Promise<BigNumber> {
    return this.adapter!.balanceOf(address)
  }

  async allowance(address: string, contract: Contract): Promise<BigNumber> {
    return this.adapter!.allowance(address, contract.address)
  }

  async approve(
    contract: Contract,
    amount: BigNumber = ethers.constants.MaxUint256
  ): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx: ContractTransaction = await this.adapter!.approve(
        contract.address,
        amount
      )

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }
}
