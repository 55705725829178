import { useState, useContext, useEffect } from 'react'

import type { WalletProvider } from './'
import { walletContext } from './'

export const useWallet = () => {
  const wallet = useContext(walletContext)
  const [isConnecting, setIsConnecting] = useState(false)

  const [updatedAt, setUpdatedAt] = useState(Date.now())

  useEffect(() => {
    const subscription = wallet!.changed$.subscribe(() =>
      setUpdatedAt(Date.now())
    )

    return () => subscription.unsubscribe()
  }, [wallet])

  return {
    wallet,
    isConnecting,
    updatedAt,
    connect: async (type: WalletProvider) => {
      setIsConnecting(true)

      try {
        await wallet?.connect(type)
      } catch (error) {
        console.warn(error)
      }

      setIsConnecting(false)
    },
    disconnect: () => wallet?.disconnect(),
  }
}
