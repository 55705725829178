import { useContext, useEffect, useState } from 'react'

import { TensetContract } from '../../contracts'
import { Utils } from '../../types'
import { walletContext } from '../../wallet'

export const useTensetContract = (network: Utils.Network) => {
  const wallet = useContext(walletContext)

  const [tensetContract, setTensetContract] = useState<
    TensetContract | undefined
  >()

  useEffect(() => {
    if (!wallet || !network) return

    const tensetContract = new TensetContract(network)

    setTensetContract(tensetContract)
  }, [wallet, network])

  return { tensetContract }
}
