import { BigNumber, ContractInterface } from 'ethers'

import { glpUpgradeAbi } from '../../assets'
import { Utils } from '../../types'
import { Contract } from '../index'

export class GlpUpgradeContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = glpUpgradeAbi,
    rpcUrl?: string
  ) {
    super(address, chain, abi, rpcUrl)
  }

  async upgrade(tokenId: BigNumber, newTierId: BigNumber) {
    this.wallet!.asBusy()

    try {
      const tx = await this.adapter!.upgrade(tokenId, newTierId)

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }
}
