import { useContext, useEffect, useState } from 'react'

import { TensetXInfinityVaultBridgeExchangerContract } from '../../contracts'
import { Utils } from '../../types'
import { walletContext } from '../../wallet'

export const useTensetXInfinityVaultBridgeExchangerContract = () => {
  const wallet = useContext(walletContext)

  const [
    tensetXInfinityVaultBridgeExchangerContract,
    setTensetXInfinityVaultBridgeExchangerContract,
  ] = useState<TensetXInfinityVaultBridgeExchangerContract | undefined>()

  const contractNetwork = Utils.Network.SMART_CHAIN

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const tensetXInfinityVaultBridgeExchangerContract =
      new TensetXInfinityVaultBridgeExchangerContract(contractNetwork)

    setTensetXInfinityVaultBridgeExchangerContract(
      tensetXInfinityVaultBridgeExchangerContract
    )
  }, [wallet, contractNetwork])

  return { tensetXInfinityVaultBridgeExchangerContract }
}
