import { useContext, useEffect, useState } from 'react'

import { Utils, walletContext } from '../..'
import { InfinityWithdrawalFeeContract } from '../../contracts'

export const useInfinityWithdrawalFeeContract = (network: Utils.Network) => {
  const wallet = useContext(walletContext)

  const [infinityWithdrawalFeeContract, setInfinityWithdrawalFeeContract] =
    useState<InfinityWithdrawalFeeContract | undefined>()

  useEffect(() => {
    if (!wallet) return

    const infinityWithdrawalFeeContract = new InfinityWithdrawalFeeContract(
      network
    )

    setInfinityWithdrawalFeeContract(infinityWithdrawalFeeContract)
  }, [wallet, network])

  return { infinityWithdrawalFeeContract }
}
