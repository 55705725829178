/**
 * Utility function to convert an ethers.js v5 error to a user-friendly message.
 * v6 have this functionality built-in.
 * see https://docs.ethers.org/v6/api/utils/errors/
 */

import { errors as EthersErrorCode } from 'ethers'

export interface EthersError extends Error {
  reason: string
  code: EthersErrorCode
  action: string
  transaction: string
}

const parsedErrorsMap: Record<EthersErrorCode, string> = {
  // Generic errors
  UNKNOWN_ERROR: 'An unknown error occurred.',
  NOT_IMPLEMENTED: 'This feature is not implemented.',
  UNSUPPORTED_OPERATION: 'This operation is not supported.',
  NETWORK_ERROR: 'There was a network error.',
  SERVER_ERROR: 'Server returned an error.',
  TIMEOUT: 'The request timed out.',

  // Operational errors
  BUFFER_OVERRUN: 'Buffer overrun.',
  NUMERIC_FAULT: 'A numeric operation failed.',

  // Argument errors
  MISSING_NEW: 'Missing new operator to an object.',
  INVALID_ARGUMENT: 'An argument provided is invalid.',
  MISSING_ARGUMENT: 'A required argument is missing.',
  UNEXPECTED_ARGUMENT: 'Received an unexpected argument.',

  // Blockchain errors
  CALL_EXCEPTION: 'The call resulted in an exception.',
  INSUFFICIENT_FUNDS: 'Insufficient funds.',
  NONCE_EXPIRED: 'Nonce has expired.',
  REPLACEMENT_UNDERPRICED: 'Transaction replacement fee is too low.',
  UNPREDICTABLE_GAS_LIMIT: 'The gas limit could not be estimated.',
  TRANSACTION_REPLACED: 'The transaction was replaced.',

  // Interaction errors
  ACTION_REJECTED: 'Transaction was declined by the user.',
}

export const DEFAULT_ERROR_MESSAGE =
  'An unexpected error occurred. Please try again.'

/**
 * Converts an ethers.js v5 error into a user-friendly message.
 *
 * @param error The error returned by ethers.js.
 * @returns A user-friendly error message.
 */
export function parseEthersError(error: EthersError): string {
  return parsedErrorsMap[error.code] || DEFAULT_ERROR_MESSAGE
}
