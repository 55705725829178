import { useContext, useEffect, useState } from 'react'

import { walletContext } from '../..'
import { InfinityVaultContract } from '../../contracts'
import { Utils } from '../../types'

export const useInfinityVaultContract = () => {
  const wallet = useContext(walletContext)

  const [infinityVaultContract, setInfinityVaultContract] = useState<
    InfinityVaultContract | undefined
  >()

  const contractNetwork = Utils.Network.SMART_CHAIN

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const infinityVaultContract = new InfinityVaultContract(contractNetwork)

    setInfinityVaultContract(infinityVaultContract)
  }, [wallet, contractNetwork])

  return { infinityVaultContract }
}
