import { useContext, useEffect, useState } from 'react'

import { config } from '../../config'
import { ZealyNftMinterContract } from '../../contracts'
import { Utils } from '../../types'
import { walletContext } from '../../wallet'

export const useZealyNftMinterContract = (isDevelopment = false) => {
  const wallet = useContext(walletContext)

  const [zealyNftMinterContract, setZealyNftMinterContract] = useState<
    ZealyNftMinterContract | undefined
  >()

  const contractNetwork = isDevelopment
    ? Utils.Network.SMART_CHAIN_TESTNET
    : Utils.Network.SMART_CHAIN

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const zealyNftMinterContract = new ZealyNftMinterContract(
      config.zealy.nftMinter[contractNetwork]!,
      contractNetwork
    )

    setZealyNftMinterContract(zealyNftMinterContract)
  }, [wallet, contractNetwork])

  return { zealyNftMinterContract }
}
