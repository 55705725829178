import { BigNumber, ContractTransaction, ethers } from 'ethers'

import { Contract, ERC20Contract } from '../'
import { tensetAbi } from '../../assets'
import { Utils } from '../../types'
import { config } from '../../config'

export class TensetContract extends ERC20Contract {
  constructor(
    chain: Utils.Network = Utils.Network.SMART_CHAIN,
    rpcUrl?: string
  ) {
    super(config.tensetToken[chain]!, chain, tensetAbi, rpcUrl)
  }

  async increaseAllowance(
    contract: Contract,
    amount: BigNumber = ethers.constants.Zero
  ): Promise<void> {
    this.wallet!.asBusy()

    try {
      const tx: ContractTransaction = await this.adapter!.increaseAllowance(
        contract.address,
        amount
      )

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }

  async burn(amount: BigNumber): Promise<void> {
    this.wallet!.asBusy()

    try {
      // why burn(uint256)? https://github.com/ethers-io/ethers.js/issues/407
      const tx: ContractTransaction =
        await this.adapter!['burn(uint256)'](amount)

      await tx.wait()
    } catch (error) {
      this.wallet!.asIdle()

      throw error
    }

    this.wallet!.asIdle()
  }
}
