import { useContext, useEffect, useState } from 'react'

import { walletContext } from '../..'
import { InfinityNftVaultContract } from '../../contracts'
import { Utils } from '../../types'

export const useInfinityNftVaultContract = () => {
  const wallet = useContext(walletContext)

  const [infinityNftVaultContract, setInfinityNftVaultContract] = useState<
    InfinityNftVaultContract | undefined
  >()

  const contractNetwork = Utils.Network.ETHEREUM

  useEffect(() => {
    if (!wallet || !contractNetwork) return

    const infinityNftVaultContract = new InfinityNftVaultContract(
      contractNetwork
    )

    setInfinityNftVaultContract(infinityNftVaultContract)
  }, [wallet, contractNetwork])

  return { infinityNftVaultContract }
}
