import { ethers } from 'ethers'

import { Connector } from './connector'

export class BitgetConnector extends Connector {
  protected activate(): void | Promise<void> {
    this.provider = new ethers.providers.Web3Provider(
      window.bitkeep!.ethereum!,
      'any'
    )
  }

  protected deactivate(): void | Promise<void> {}
}
