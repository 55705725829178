[
  {
    "type": "function",
    "name": "batchSafeTransferFrom",
    "inputs": [
      { "name": "to", "type": "address", "internalType": "address" },
      {
        "name": "collections",
        "type": "address[]",
        "internalType": "address[]"
      },
      { "name": "ids", "type": "uint256[]", "internalType": "uint256[]" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  { "type": "error", "name": "ArraysLengthMissmatch", "inputs": [] },
  { "type": "error", "name": "UnacceptableValue", "inputs": [] }
]
