import { Config, Utils } from './types'

const {
  SMART_CHAIN,
  SMART_CHAIN_TESTNET,
  ETHEREUM,
  POLYGON,
  ARBITRUM_ONE,
  BASE,
  PHALCON_BSC,
  PHALCON_ETH,
  ANVIL_BSC,
  ANVIL_ETH,
} = Utils.Network

export const config: Config = {
  walletConnectProjectId: 'ff8ddbf4a743bdefef7bc7acbad40268',
  tensetToken: {
    [SMART_CHAIN]: '0x1AE369A6AB222aFF166325B7b87Eb9aF06C86E57',
    [SMART_CHAIN_TESTNET]: '0x24937202657085e5991b4197e913eb688b57d084',
    [PHALCON_BSC]: '0x1AE369A6AB222aFF166325B7b87Eb9aF06C86E57',
    [ANVIL_BSC]: '0x1AE369A6AB222aFF166325B7b87Eb9aF06C86E57',
  },
  tensetX: {
    infinityVaultBridgeExchanger: {
      [SMART_CHAIN]: '0x2Bd1e261fF3CAB011479Cc7EA46FbB1fb24e00Ab',
      [SMART_CHAIN_TESTNET]: '0xf8cc54cff031ff0c9ac71728c6f39b9ade7cb120',
    },
    infinityVaultBurnExchanger: {
      [SMART_CHAIN]: '0x8fF29C45Fd4F5F6Ee483C6299b1039890b25B985',
      [SMART_CHAIN_TESTNET]: '0xf07ec5fa63f57ef97d5155fec1d09cdf14a78bac',
    },
    token: {
      [SMART_CHAIN]: '0xA6054bb258059b59E9Dbb31D19eaf19Ba6519e44',
      [SMART_CHAIN_TESTNET]: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    },
  },
  gemLaunchPlatform: {
    subscribe: {
      [SMART_CHAIN]: '0x5c6B9f83a7a5E3C57Ce4670cE6eb77725cD3bd23',
      [PHALCON_BSC]: '0x5c6B9f83a7a5E3C57Ce4670cE6eb77725cD3bd23',
      [ANVIL_BSC]: '0x5c6B9f83a7a5E3C57Ce4670cE6eb77725cD3bd23',
    },
    membership: {
      [SMART_CHAIN]: '0x008C037BBD16F10682a4c03Cd43852fdE6c9cf16',
      [PHALCON_BSC]: '0x008C037BBD16F10682a4c03Cd43852fdE6c9cf16',
      [ANVIL_BSC]: '0x008C037BBD16F10682a4c03Cd43852fdE6c9cf16',
    },
    withdraw: {
      [SMART_CHAIN]: '0xEB7e613D8cF6dF9e3c8FE595e3622fb575d32b72',
      [PHALCON_BSC]: '0xEB7e613D8cF6dF9e3c8FE595e3622fb575d32b72',
      [ANVIL_BSC]: '0xEB7e613D8cF6dF9e3c8FE595e3622fb575d32b72',
    },
    extend: {
      [SMART_CHAIN]: '0xFf29E7106DBA06c015ba44A329c129f2a72Ca581',
      [PHALCON_BSC]: '0xFf29E7106DBA06c015ba44A329c129f2a72Ca581',
      [ANVIL_BSC]: '0xFf29E7106DBA06c015ba44A329c129f2a72Ca581',
    },
    upgrade: {
      [SMART_CHAIN]: '0xfE94d66F89ab88C1bAFC9962AF0820F182FAeE2b',
      [PHALCON_BSC]: '0xfE94d66F89ab88C1bAFC9962AF0820F182FAeE2b',
      [ANVIL_BSC]: '0xfE94d66F89ab88C1bAFC9962AF0820F182FAeE2b',
    },
    lifetimeMembership: {
      [SMART_CHAIN]: '0x1A77DaC9971Cc1A3eE70ECF69eFc0D36EeF6597C',
      [PHALCON_BSC]: '0x1A77DaC9971Cc1A3eE70ECF69eFc0D36EeF6597C',
      [ANVIL_BSC]: '0x1A77DaC9971Cc1A3eE70ECF69eFc0D36EeF6597C',
    },
    oldWithdraw: {
      [SMART_CHAIN]: '0x72888E01fA7C1d3250675F9d6aB58027531e63EF',
      [PHALCON_BSC]: '0x72888E01fA7C1d3250675F9d6aB58027531e63EF',
      [ANVIL_BSC]: '0x72888E01fA7C1d3250675F9d6aB58027531e63EF',
    },
  },
  infinity: {
    vault: {
      [SMART_CHAIN]: '0x0e3234056ad5b469ada1afd30ee3719ac99a361f',
    },
    vaultBridge: {
      [SMART_CHAIN]: '0x15c3348a1687a6ba18e48c04d6356e22a9429ed1',
    },
    nftVault: {
      [ETHEREUM]: '0xb014bb6c2a01cc367948acea5ba64053b8057b41',
    },
    withdrawalFee: {
      [SMART_CHAIN]: '0x257ead54f95462e99db19925de152fbff90d5032',
      [ETHEREUM]: '0x257ead54f95462e99db19925de152fbff90d5032',
      [POLYGON]: '0x985602D627e948d5641291A063fEAdF1C1De874c',
      [ARBITRUM_ONE]: '0xfd8D6D8F394d787e460BB4E9C05C3B49cB516d73',
      [BASE]: '0xf8528c90e2aa2D1267be0c6Ff27CB410E32876B5',
    },
    nftWithdrawalFee: {
      [ETHEREUM]: '0xB3dB3B0251806a274b16a66d99b46A19a1c5050a',
      [POLYGON]: '0xB3dB3B0251806a274b16a66d99b46A19a1c5050a',
    },
  },
  zealy: {
    rewards: {
      [SMART_CHAIN]: '0xd247a6a299944ec1064124c250396e4b0092ff10',
      [SMART_CHAIN_TESTNET]: '0xBd1c98BaC4c2A87EA6afD5c03513AAec124BFB32',
    },
    nft: {
      [SMART_CHAIN]: '0xfaEc3ba81626Ce2027F625A14321889E8a0F4F81',
      [SMART_CHAIN_TESTNET]: '0xb9e60AF66696DC1189Ad57238205b01Ff9C1215D',
    },
    nftMinter: {
      [SMART_CHAIN]: '0xa6e811017fFAfE88dB9AEFf2b73d93b66B809182',
      [SMART_CHAIN_TESTNET]: '0x6B21fca802A7Da87BBb88aE79fd7e3172fA2FD1A',
    },
  },
  utils: {
    erc721Multisender: {
      [SMART_CHAIN]: '0xFCd03e9b949B288ecf5b66790a40d2fFc49019Fa',
      [ETHEREUM]: '0x7b1323f91Cb82C664ba070c32E57bCC24F9cF6fd',
      [PHALCON_BSC]: '0xFCd03e9b949B288ecf5b66790a40d2fFc49019Fa',
      [PHALCON_ETH]: '0x7b1323f91Cb82C664ba070c32E57bCC24F9cF6fd',
      [ANVIL_BSC]: '0xFCd03e9b949B288ecf5b66790a40d2fFc49019Fa',
      [ANVIL_ETH]: '0x7b1323f91Cb82C664ba070c32E57bCC24F9cF6fd',
    },
  },
  agreementsApiUrl: 'https://www.tenset.io/api/agreements',
}
