import type { ContractInterface } from 'ethers'

import { zealyNftMinterAbi } from '../../assets'
import { Contract } from '..'
import { Utils } from '../../types'

export class ZealyNftMinterContract extends Contract {
  constructor(
    address: string,
    chain: Utils.Network,
    abi: ContractInterface = zealyNftMinterAbi
  ) {
    super(address, chain, abi)
  }

  async ownerOf(tokenId: string): Promise<string> {
    return await this.adapter!.ownerOf(tokenId)
  }
}
